import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SiDocker} from 'react-icons/si';
import {BiCodeBlock} from 'react-icons/bi';
import {AiOutlineCloudServer} from 'react-icons/ai';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function ThalesTimeLine() {
    const classes = useStyles();

    return (
        <Timeline align="alternate">

            <TimelineItem>
                {/*<TimelineOppositeContent>*/}
                {/*    <Typography>*/}
                {/*        10:00 am*/}
                {/*    </Typography>*/}
                {/*</TimelineOppositeContent>*/}
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <BiCodeBlock  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Development
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Python Django/DRF/SWAGGER</Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>AngularJS, Html, CSS </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                {/*<TimelineOppositeContent>*/}
                {/*    <Typography>*/}
                {/*        10:00 am*/}
                {/*    </Typography>*/}
                {/*</TimelineOppositeContent>*/}
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <SiDocker  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Dockerization & CI
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Docker, Sonar, GitLab</Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Jenkins jobs</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <AiOutlineCloudServer  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Interoperability & Software architecture
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>API REST - JSON </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Software Design and Architecture </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

        </Timeline>
    );
}
