import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Flag from 'react-world-flags'

class Header extends Component {

    constructor(props) {
        super(props);
        this.toggleChecked=this.toggleChecked.bind(this)
    }
    toggleChecked() {

        if (this.props.lang === "fr") {
            this.props.changeLang("en")

        } else {
            this.props.changeLang("fr")

        }

    }

  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var about = this.props.data.about;
      var home = this.props.data.home;
      var works = this.props.data.works;
      var resume = this.props.data.resume;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var job= this.props.data.job;
      var city= this.props.data.address.city;
      var services = this.props.data.services.map(function(service){
          return <Chip style={{fontSize:'15px', margin:'5px', color:'#424242', fontWeight:'bold'}} label={service} />
      })
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })

    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">{home}</a></li>
            <li><a className="smoothscroll" href="#about">{about}</a></li>
	         <li><a className="smoothscroll" href="#resume">{resume}</a></li>
            <li><a className="smoothscroll" href="#portfolio">{works}</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
             <li>
                 <Grid component="label" container style={{marginLeft:'10px'}} >
                     <Grid style={{fontSize:'11px',color:'white'}} item>
                         <Flag code={ "FR" } style={{height:'13px',width:'auto',marginRight:'10px'}}/>
                     </Grid>

                     <Grid item>
                         <Switch size="small"  checked={this.props.lang!=="fr"} onChange={this.toggleChecked}/>
                     </Grid>
                     <Grid style={{fontSize:'11px',color:'white'}}  item>

                         <Flag code={ "gb" } style={{height:'13px',width:'auto',marginLeft:'10px'}}/>

                     </Grid>
                 </Grid>
             </li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h2 className="responsive-headline" style={{color:'white'}}>{name}</h2>
            <h5 style={{color:'#fff', fontWeight:'700'}}>{job}<span>{occupation}</span></h5><br/>
             <h5 style={{color:'#fff'}}>
             {description}</h5>
             <br/>
             {services}
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
