import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SiAzuredevops} from 'react-icons/si';
import {BiCodeBlock} from 'react-icons/bi';
import {SiMicrosoftazure} from 'react-icons/si';
import {AiFillBuild} from 'react-icons/ai';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function ThalesTimeLine() {
    const classes = useStyles();

    return (
        <Timeline align="alternate">
            <TimelineItem>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <SiAzuredevops style={{width:'30px', height:'auto',  color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            DevOps
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Docker, Azure DevOps, Gitlab CI/CD, Terraform, Kubernetes, ELK Stack (Elasticsearch, Logstash, Kibana), Prometheus</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                {/*<TimelineOppositeContent>*/}
                {/*    <Typography>*/}
                {/*        10:00 am*/}
                {/*    </Typography>*/}
                {/*</TimelineOppositeContent>*/}
                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <BiCodeBlock  style={{width:'30px', height:'auto',color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Development
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Python: Django, DRF, API REST, Celery/Brokers, SWAGGER </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>React/JavaScript: Redux, npm, NodeJS, HTML/CSS </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>C# .NET</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <SiMicrosoftazure  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Cloud providers
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Azure: AzureDevops, Blob, Container registry, App Service </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <AiFillBuild  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Software architecture
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>SOA Design, Lead Dev, SonarQube (Code Quality and Code Security) </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Code-review, Security Partner, Security Analysis of Docker Containers </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}
