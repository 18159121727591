import React, { Component } from 'react';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#38b6ff'
    }
  }
});
class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {},
      lang: 'fr',
      file: '/resumeData-fr.json',
    };
    this.changelang=this.changelang.bind(this)
    // ReactGA.initialize('');
    // ReactGA.pageview(window.location.pathname);

  }

  getResumeData(){
    $.ajax({
      url:this.state.file,
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();

  }

  changelang(){
    if (this.state.lang === "fr") {
      this.setState({lang: "en",file:'/resumeData-en.json'},()=>this.getResumeData())

    } else {
      this.setState({lang: "fr",file:'/resumeData-fr.json'},()=>this.getResumeData())

    }
  }

  render() {
    return (
        <MuiThemeProvider theme={theme}>

        <div className="App">
        {/*//Pour le nom de dommaine je pense à sa-dev.fr il est dispo*/}
        <Header data={this.state.resumeData.main} lang={this.state.lang} changeLang={this.changelang}/>
        <About data={this.state.resumeData.main}/>
        <Resume data={this.state.resumeData.resume}/>
        <Portfolio data={this.state.resumeData}/>
        <Testimonials data={this.state.resumeData.main}/>
        <Contact data={this.state.resumeData.main}/>
        <Footer data={this.state.resumeData.main}/>
      </div>
        </MuiThemeProvider>
    );
  }
}

export default App;
