import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {ImStackoverflow} from 'react-icons/im';
import {RiToolsLine} from 'react-icons/ri';
import {FaAws} from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function VroomerTimeLine() {
    const classes = useStyles();

    return (
        <Timeline align="alternate">
            <TimelineItem>

                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <ImStackoverflow  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Tech Stack
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Django, Python, Docker, React & NextJS, Javascript, Strapi, Celery, Amazon Simple Queue Service, GitlabCI, K8S, Terraform, ELK, DRF, SWAGGER, AWS, PostgreSQL..</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                {/*<TimelineOppositeContent>*/}
                {/*    <Typography>*/}
                {/*        10:00 am*/}
                {/*    </Typography>*/}
                {/*</TimelineOppositeContent>*/}
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <FaAws  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            Hosting & Cloud
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Amazon Web Services: EC2, EB (PAAS), ELB, ECR, Route53, S3, CloudWatch SQS, etc... </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>CLOUD Expense Management, Load Balancing</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                     <TimelineDot color="primary">
                        <RiToolsLine  style={{width:'30px', height:'auto', color:'white'}}/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4"  style={{fontWeight:'bold'}}>
                            SEO & Marketing tools
                        </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Server Side Rendering (SSR) - NextJS  </Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Stripe Blog (ReactJS), Optimization SEO (12K unique visitors/month)</Typography>
                        <Typography  variant="h6"  style={{fontWeight:'bold', color:'gray'}}>Performance optimization - PageSpeed Insights</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

        </Timeline>
    );
}
