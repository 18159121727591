import React, { Component } from 'react';

class Testimonials extends Component {

  render() {

    if(this.props.data){
      var testimonials = this.props.data.contactmessage;
    }

    return (
      <section id="testimonials">
          <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span>Available</span></h1>
            </div>

            <div className="ten columns flex-container" style={{color:'white', fontWeight:"900", fontSize:'25px'}}>
                      {testimonials}
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Testimonials;
