import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {Button,CircularProgress,Box} from "@material-ui/core";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

class Contact extends Component {

    constructor() {
        super();
        this.onChange=this.onChange.bind(this)
        this.sendEmail=this.sendEmail.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.state = {
            isVerified: false,
            isSent: false,
            isLoading: false,
            contactName:"",
            contactEmail:"",
            contactMessage:"",

        }    }
    onChange() {
        this.setState({isVerified:true})


    }
    handleChange(e) {

        this.setState({[e.target.name]:e.target.value},()=>console.log(this.state))


    }

    sendEmail() {

        if(this.state.contactName ==="" ||this.state.contactEmail ==="" || this.state.contactMessage ===""){
        //popup alert
            Swal.fire({
                title: 'Error!',
                text: 'Please complete all required fields',
                icon: 'error'})

        }else {
            this.setState({isLoading: true})

            emailjs.send('service_v23mlac', 'template_0awftdr', {
                from_name: this.state.contactName,
                message: this.state.contactMessage,
                from_adr:  this.state.contactEmail,
            }, "user_LeSAPBj8awNeNtlXUJgco")
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    this.setState({isSent: true, isLoading: false})
                }, (err) => {
                    console.log('FAILED...', err);
                });
        }

    }


  render() {

    if(this.props.data){
      var contactMe = this.props.data.contactMe;

      var sendmessage = this.props.data.sendMessage;
      var send = this.props.data.send;
      var subject = this.props.data.subject;
      var nameItem = this.props.data.nameItem;
    }

    return (
      <section id="contact">

         <div className="row section-head">





         </div>

         <div className="row">
            <div className="eight columns">

               <form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>

                  <div>
						   <label htmlFor="contactName">{nameItem} <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>


                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"  onChange={this.handleChange}></textarea>
                  </div>

                  <div hidden={this.state.isSent}>
                        {!this.state.isLoading ?


                      this.state.isVerified?
                     <Button size='large' onClick={this.sendEmail} style={{fontSize:'16px'}} className="submit">{send}</Button>:
                          <ReCAPTCHA style={{textAlign:'-webkit-center'}}
                              sitekey="6Leo-nwcAAAAAM-GVoSxV5ZPIRdw2k6kPhpER4I7"
                              onChange={this.onChange}
                          />:
                            <Box  className="submit" sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Box>

                        }
                  </div>


					</fieldset>
				   </form>

           <div hidden={!this.state.isSent}  style={{ textAlign: 'center' }}>
                  <i className="fa fa-check"> </i>{" "+sendmessage}<br />
           </div>
           </div>


            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">

					   <h4>Contact</h4>
					   <p className="address">
						   {contactMe}<br />

					   </p>
				   </div>

               {/*<div className="widget widget_tweets">*/}
               {/*   <h4 className="widget-title">Latest Tweets</h4>*/}
               {/*   <ul id="twitter">*/}
               {/*      <li>*/}
               {/*         <span>*/}
               {/*         This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet.*/}
               {/*         Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum*/}
               {/*         <a href="#">http://t.co/CGIrdxIlI3</a>*/}
               {/*         </span>*/}
               {/*         <b><a href="#">2 Days Ago</a></b>*/}
               {/*      </li>*/}
               {/*      <li>*/}
               {/*         <span>*/}
               {/*         Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,*/}
               {/*         eaque ipsa quae ab illo inventore veritatis et quasi*/}
               {/*         <a href="#">http://t.co/CGIrdxIlI3</a>*/}
               {/*         </span>*/}
               {/*         <b><a href="#">3 Days Ago</a></b>*/}
               {/*      </li>*/}
               {/*   </ul>*/}
		       {/*  </div>*/}
            </aside>
      </div>
   </section>
    );
  }
}

export default Contact;
